import axios from 'axios';
import store from './store';
import router from './router';

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;
console.log(process.env.VUE_APP_BACKEND_URL);

axios.interceptors.request.use(
  config => {
    const token = store.state.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      store.dispatch('logout');
      router.push('/');
    }
    return Promise.reject(error);
  }
);

export default axios;
