<template>
  <footer class="footer">
    <div class="left-links">
      <router-link class="login-link" to="/auth/login" v-if="!isLoggedIn">Login</router-link>
      <a v-if="isLoggedIn" @click="logout" class="logout-link">Logout</a>
    </div>
    <div class="right-links">
      <router-link to="/terms">Impressum</router-link>
      <router-link to="/privacy-policy">Datenschutzerklärung</router-link>
    </div>
  </footer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'FooterComponent',
  computed: {
    ...mapGetters(['isLoggedIn'])
  },
  methods: {
    ...mapActions(['logout']),
    async handleLogout() {
      try {
        await this.logout();
      } catch (error) {
        console.error('Fehler beim Logout:', error);
      }
    }
  }
};
</script>

<style scoped>
.footer {
  background-color: #0b1735;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-links {
  display: flex;
  align-items: center;
}

.right-links {
  display: flex;
  align-items: center;
}

.footer a {
  color: #fff;
  padding-right: 20px;
  text-decoration: none;
}

.footer a.login-link {
  color: #444;
}
.footer a.logout-link {
  cursor: pointer;
}
</style>
